import axios from 'axios'
import localStorage from '../store/LocalStorage'

let client = axios.create()
export const setAxiosHeaderAuthorization = () => {
    const token = localStorage.getAccessToken()
    //console.log("axios token :", token)
    if (token) {
        client.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
        //console.log("delete axios token :", token)
        delete client.defaults.headers.common['Authorization']
    }
    return client
}

export default client