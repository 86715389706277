import localStorage from "./LocalStorage"

export const InitialState = {
    login_profile : localStorage.getAllAccess(),
    error_code: "",
    error_message : '',
    customer_balance:0,
    customer_information: undefined,
    customer_register:{},
    logo_register: "",
    logo_main: "",
}

export const GlobalReducer = (state, action) =>{
    //console.log('GlobalReducer : ', state, action)
    switch(action.type){
        case 'login_profile':
            return {...state, login_profile : action.payload.data}
        case 'error_message_dialog':
            //console.log("action.payload.data :", action.payload.data)
            if (action.payload.data === 'missing or malformed jwt'){
                return {...state, error_message : 'ขออภัย Session หมดอายุ กรุณาเข้าสู่ระบบอีกครั้ง'}
            }
            return {...state, error_message : action.payload.data}
        case 'customer_balance':
            return {...state, customer_balance : action.payload.data}
        case 'customer_information':
            return {...state, customer_information : action.payload.data}
        case 'customer_register':
            return {...state, customer_register : {...state.customer_register, ...action.payload.data}}
        case 'logo_register':
            return {...state, logo_register : action.payload.data}
        case 'logo_main':
            return {...state, logo_main : action.payload.data}
        case 'error_message_dialog_with_code':
            if (action.payload.data === 'missing or malformed jwt'){
                return {...state, error_code: "401", error_message : 'ขออภัย Session หมดอายุ กรุณาเข้าสู่ระบบอีกครั้ง'}
            }
            return {...state, error_code:action.payload.data.error_code, error_message : action.payload.data.error_message}
        default:
            return InitialState
    }

}