import React, {useEffect} from "react";
import {Grid, Grow} from "@material-ui/core";
import './selectbox.css';
import Dialog from '@material-ui/core/Dialog';
import GameBoxPage from "./index";
import './gamebox.css';
import Filter1Icon from '@material-ui/icons/Filter1';
import Filter2Icon from '@material-ui/icons/Filter2';
import Filter3Icon from '@material-ui/icons/Filter3';
import Filter4Icon from '@material-ui/icons/Filter4';
import Filter5Icon from '@material-ui/icons/Filter5';
import Filter6Icon from '@material-ui/icons/Filter6';
import Filter7Icon from '@material-ui/icons/Filter7';
import Filter8Icon from '@material-ui/icons/Filter8';
import Filter9Icon from '@material-ui/icons/Filter9';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Grow ref={ref} {...props} />;
});

export default function SelectBox(){

    const [open, setOpen] = React.useState(false);
    const [buttonStyle, setButtonStyle] = React.useState("square-bg-purple");
    const [boxId, setBoxId] = React.useState("0")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const openModal = (boxId)=>{
        setBoxId(boxId)
        setOpen(true)
    }

     useEffect(()=>{

         const intervalId =  setInterval(() => {
             if (buttonStyle === "square-bg-purple"){
                 setButtonStyle("square-bg-dark-purple")
             }else{
                 setButtonStyle("square-bg-purple")
             }
         }, 1500)

         return () => clearInterval(intervalId)

     },[buttonStyle])
    return(
        <>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}  PaperProps={{
                style: {
                    backgroundColor: '#000000',
                },
            }}
            >
                {/*<DialogTitle id="simple-dialog-title">Set backup account</DialogTitle>*/}
                <GameBoxPage boxId={boxId}/>
            </Dialog>

            <Grid container className={"context-margin-style"}>
                <Grid item>
                    <div >กรุณาเลือกกล่องโชคดีที่ต้องการนะคะ</div>
                </Grid>
            </Grid>
            <div className={"frame-padding"}>
                <Grid container spacing={5}>
                    <Grid item xs={4} sm={4} md={4} >
                        <div className={"context-style"}>
                            <span className={`btn-square ${buttonStyle}`} onClick={
                                ()=> (openModal("1"))
                            }>
                                <Filter1Icon fontSize={"large"}/>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} >
                        <div className={"context-style"}>
                            <span className={`btn-square ${buttonStyle}`}onClick={
                                ()=> (openModal("2"))
                            }>
                                <Filter2Icon fontSize={"large"}/>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} >
                        <div className={"context-style"}>
                            <span className={`btn-square ${buttonStyle}`}onClick={
                                ()=> (openModal("3"))
                            }>
                                <Filter3Icon fontSize={"large"}/>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} >
                        <div className={"context-style"}>
                            <span className={`btn-square ${buttonStyle}`}onClick={
                                ()=> (openModal("4"))
                            }>
                                <Filter4Icon fontSize={"large"}/>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} >
                        <div className={"context-style"}>
                            <span className={`btn-square ${buttonStyle}`}onClick={
                                ()=> (openModal("5"))
                            }>
                                <Filter5Icon fontSize={"large"}/>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} >
                        <div className={"context-style"}>
                            <span className={`btn-square ${buttonStyle}`}onClick={
                                ()=> (openModal("6"))
                            }>
                                <Filter6Icon fontSize={"large"}/>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} >
                        <div className={"context-style"}>
                            <span className={`btn-square ${buttonStyle}`}onClick={
                                ()=> (openModal("7"))
                            }>
                                <Filter7Icon fontSize={"large"}/>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} >
                        <div className={"context-style"}>
                            <span className={`btn-square ${buttonStyle}`}onClick={
                                ()=> (openModal("8"))
                            }>
                                <Filter8Icon fontSize={"large"}/>
                            </span>
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} >
                        <div className={"context-style"}>
                            <span className={`btn-square ${buttonStyle}`}onClick={
                                ()=> (openModal("9"))
                            }>
                                <Filter9Icon fontSize={"large"}/>
                            </span>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )

}

