import React,{useReducer, useContext} from "react";
import {GlobalReducer, InitialState} from './GlobalReducer'

export const StateContext = React.createContext()

export const GlobalProvider = ({ children }) => {
    return (
        <StateContext.Provider value={useReducer(GlobalReducer, InitialState)}>
            {children}
        </StateContext.Provider>
    )
}

export const useGlobalStore = () => useContext(StateContext)