import React, {useEffect, useState} from "react";
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import KeyboardArrowUpTwoToneIcon from '@material-ui/icons/KeyboardArrowUpTwoTone';
import KeyboardArrowDownTwoToneIcon from '@material-ui/icons/KeyboardArrowDownTwoTone';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import LayersSharpIcon from '@material-ui/icons/LayersSharp';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import styled from 'styled-components'
import './gamebox.css';
import customer_api from "../../api/customer_api";
import {useGlobalStore} from "../../store/GlobalContext";
import { makeStyles } from '@material-ui/core/styles';
import ResultGameBox from "./resultgamebox";

const Container = styled.div`
    padding-top: 20px;
    display: grid;
    grid-template-rows: auto auto auto auto;
    grid-gap: 20px;
    justify-content: center;
`

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

export default function GameBoxPage ({boxId}) {
    const classes = useStyles();
    const [ { login_profile }, dispatch ] = useGlobalStore()
    const [cubeClass, setCubeClass] = useState("cube show-front")
    const [btnStyle, setBtnStyle] = useState("submit")
    const [step, setStep] = useState(0);
    const [resultResponse, setResultResponse] = useState("")
    const [luckyAmount, setLuckyAmount] = useState("0")
    const [diceShow, setDiceShow] = useState({top:1, left:1, right:1, bottom:1})

    const calculateFront = (selected)=>{
        //console.log("selected :", selected)

        if (selected === "top") {
            let topCal = diceShow.top

            if (diceShow.top === 4){
                setDiceShow({
                    ...diceShow,
                    top: 1,
                })
                setCubeClass(`cube show-front`)
                return
            }

            setDiceShow({
                ...diceShow,
                top: topCal + 1,
            })
            setCubeClass(`cube show-top${diceShow.top}`)
        }

        if (selected === "left") {
            let leftCal = diceShow.left

            if (diceShow.left === 4){
                setDiceShow({
                    ...diceShow,
                    left: 1,
                })
                setCubeClass(`cube show-front`)
                return
            }

            setDiceShow({
                ...diceShow,
                left: leftCal + 1,
            })
            setCubeClass(`cube show-left${diceShow.left}`)
        }

        if (selected === "right") {
            let rightCal = diceShow.right

            if (diceShow.right === 4){
                setDiceShow({
                    ...diceShow,
                    right: 1,
                })
                setCubeClass(`cube show-front`)
                return
            }

            setDiceShow({
                ...diceShow,
                right: rightCal + 1,
            })
            setCubeClass(`cube show-right${diceShow.right}`)
        }

        if (selected === "bottom") {
            let bottomCal = diceShow.bottom

            if (diceShow.bottom === 4){
                setDiceShow({
                    ...diceShow,
                    bottom: 1,
                })
                setCubeClass(`cube show-front`)
                return
            }

            setDiceShow({
                ...diceShow,
                bottom: bottomCal + 1,
            })
            setCubeClass(`cube show-bottom${diceShow.bottom}`)
        }


        //console.log("diceShow :", diceShow)
    }

    const pickSize = (selected) =>{
        calculateFront(selected)


        //setCubeClass(`cube show-${selected}`)
    }




    const PAGES = [
        {
            render: (
                <>
                    {/*<div>*/}
                    {/*    <p className={"text-align-center-pd10"}>คุณได้เลือกกล่องโชคดีหมายเลข {boxId}</p>*/}
                    {/*</div>*/}
                    <div className={cubeClass} style={{ margin: '40px'}}>
                        <div className="cube__face cube__face--front flex-space-around">
                            <div>
                                <span className="dot"/>
                            </div>
                        </div>
                        <div className="cube__face cube__face--right flex-space-around">
                            <div>
                                <div>
                                    <span className="dot"/>
                                </div>
                                <div>
                                    <span className="dot"/>
                                </div>
                            </div>
                        </div>
                        <div className="cube__face cube__face--back flex-space-around">
                            <div>
                                <div>
                                    <span className="dot"/>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span className="dot"/>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span className="dot"/>
                                </div>
                            </div>
                        </div>
                        <div className="cube__face cube__face--left flex-space-around">
                            <div>
                                <div>
                                    <span className="dot"/>
                                </div>
                                <div>
                                    <span className="dot"/>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span className="dot"/>
                                </div>
                                <div>
                                    <span className="dot"/>
                                </div>
                            </div>
                        </div>
                        <div className="cube__face cube__face--top flex-space-around">
                            <div>
                                <div>
                                    <span className="dot"/>
                                </div>
                                <div>
                                    <span className="dot"/>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span className="dot"/>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span className="dot"/>
                                </div>
                                <div>
                                    <span className="dot"/>
                                </div>
                            </div>
                        </div>
                        <div className="cube__face cube__face--bottom flex-space-around">
                            <div>
                                <div>
                                    <span className="dot"/>
                                </div>
                                <div>
                                    <span className="dot"/>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span className="dot"/>
                                </div>
                                <div>
                                    <span className="dot"/>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <span className="dot"/>
                                </div>
                                <div>
                                    <span className="dot"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className={`text-align-center-pd10 label-text-swap1`}>กดเพื่อเลือกแต้มลูกเต๋า</p>
                    </div>
                    <div style={{display: "flex", justifyContent: 'center'}}>
                            <span className="btn info">
                                <IconButton style={{color: "white", margin:"5px"}} onClick={()=>pickSize("top")}>
                                    <KeyboardArrowUpTwoToneIcon aria-label="top" value="top" />
                                </IconButton>
                            </span>
                    </div>
                    <div style={{display: "flex", justifyContent: 'center'}}>
                            <span className="btn info">
                                <IconButton style={{color: "white", margin:"5px"}} onClick={()=>pickSize("left")}>
                                    <ArrowBackIosOutlinedIcon aria-label="front" value="left" />
                                </IconButton>
                            </span>
                            <span className="btn info-green">
                                <IconButton style={{color:"white", margin:"5px"}} onClick={()=>randomBonus()}>
                                    {/*<LayersSharpIcon aria-label="front" value="front" onClick={()=>pickSize("front")}/>*/}
                                    OK
                                </IconButton>
                            </span>
                            <span className="btn info">
                                    <IconButton style={{color: "white", margin:"5px"}} onClick={() => pickSize("right")}>
                                        <ArrowForwardIosOutlinedIcon aria-label="front" value="right" style={{borderColor:process.env.REACT_APP_PRIMARY_THEME_COLOR}}/>
                                    </IconButton>
                            </span>
                    </div>
                    <div style={{display: "flex", justifyContent: 'center'}}>
                        <Box mx={2}>
                                <span className="btn info">
                                    <IconButton style={{color: "white", margin:"5px"}} onClick={()=>pickSize("bottom")}>
                                        <KeyboardArrowDownTwoToneIcon aria-label="front" value="bottom" />
                                    </IconButton>
                                </span>
                        </Box>
                    </div>
                    {/*<div style={{display:"flex", justifyContent:"center"}}>*/}
                    {/*    <span className={`btn btn-large2 ${btnStyle}`} onClick={()=>randomBonus()}><FavoriteOutlinedIcon/>ขอให้ลูกค้าเฮงๆนะคะ</span>*/}
                    {/*</div>*/}
                </>
            ),
            name: "lucky-random",
        },
        {
            render: (
                <ResultGameBox message={resultResponse} luckyAmount={luckyAmount}/>
            ),
            name: "lucky-result"
        }
    ]


    const randomBonus = ()=>{
        const userId = login_profile.customer_infos.customer_agents[0].user_id

        const request = {
            user_id: userId,
            box_id: boxId,
            box_side : cubeClass,
        }

        customer_api.LUCKY_DRAW(request)
            .then( (response) => {
                //console.log("response.data : ", response.data)
                setResultResponse(response.data.message)
                setLuckyAmount(response.data.data.amount)
                setStep((s) => s + 1)
            } )
            .catch( error => {
                setResultResponse(`${error.response.data.message} ${error.response.data.code || ""}`)
                setStep((s) => s + 1)
            } )
    }

    const checkGameAvailable = async() => {
        await customer_api.CHECK_LUCKY_DRAW()
            .then( response => {
                const { code } = response.data
                if (code === "200"){
                    //console.log("check lucky draw pass")
                }
            } )
            .catch( error => {
                //console.log("error check lucky draw data : ", error.response.data)
                setResultResponse(`${error.response.data.message} ${error.response.data.code || ""}`)
                setStep((s) => s + 1)
            } )
    }

    useEffect(()=>{
        const intervalId =  setInterval(() => {
            if (btnStyle === "submit"){
                setBtnStyle("info")
            }else{
                setBtnStyle("submit")
            }
        }, 1000)

        return () => clearInterval(intervalId)

    },[btnStyle])

    useEffect(()=>{
        checkGameAvailable()
    },[])

    return(
        <Container>
            {
                PAGES[step].render
            }
        </Container>
    )

}