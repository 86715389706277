import {createMuiTheme} from "@material-ui/core/styles";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: process.env.REACT_APP_PRIMARY_THEME_COLOR
        },
        info: {
            main: '#2196f3'
        },
        background:{
            default : '#000000'
        },
        text: {
            primary: '#ffffff',
            secondary: process.env.REACT_APP_PRIMARY_THEME_COLOR
        }
    },
    typography :{
        fontFamily: 'K2D',
        fontSize: 17,
        colorTextSecondary: {
            color: 'white',
        },
    },
    indicator: {
        backgroundColor: 'white',
    },
})

export default theme