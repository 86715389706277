import React, {useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useGlobalStore} from "../../store/GlobalContext";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        // border: '1px solid #f0aa08',
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: '#000000',
        color: `${theme.palette.primary.main}`,
        justifyContent: 'center',
        width: '100%'
    },
    rootNoBorder:{
        backgroundColor: '#000000',
        color: `${theme.palette.primary.main}`,
        justifyContent: 'center',
        width: '100%',
        whiteSpace: 'pre-wrap',
    }
}));


export default function ErrorDialog() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [{ error_code, error_message }, dispatch] = useGlobalStore()

    const handleClose = () => {
        setOpen(false);
        dispatch({type: 'error_message_dialog', payload: {data:''}})
        // console.log("error code/message: ", error_code, "/", error_message)
        if (error_code === "403"){
            window.location.reload();
        }
    };

    useEffect(()=>{
        if (error_message !== ''){
            setOpen(true);
        }
    },[error_message])


    return (
        <Dialog
            open={open}
            onClose={handleClose}
            disableBackdropClick={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
        >
            <DialogTitle id="alert-dialog-title" className={classes.root}>{"แจ้งเตือน"}</DialogTitle>
            <DialogContent className={classes.root}>
                <DialogContentText className={`${classes.rootNoBorder} text-sm`} id="alert-dialog-description">
                    {error_message}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.root}>
                <Button onClick={handleClose} color="primary" variant={"contained"}>
                    ตกลง
                </Button>
            </DialogActions>
        </Dialog>
    );
}