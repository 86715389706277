import AES from 'crypto-js/aes'
import Crypto from 'crypto-js'
import store from 'store'

const UFA_ACCESS_TOKEN = 'customer_access_token'
const UFA_ACCESS_ROLE = 'customer_access_role'
const UFA_ACCESS_NAME = 'customer_access_name'
const UFA_CUSTOMER_INFOS = 'customer_access_infos'
const UFA_CUSTOMER_EXPIRE_TIME = 'customer_expire_time'
const USER_CUSTOMER = "user_id"
const WELCOME_NEWS = 'news'

const getSecret = () => {
    const envSecret = process.env.REACT_APP_UFA_ACCESS_TOKEN
    return envSecret
}

const encrypt = (value) => {
    const valueJSON = JSON.stringify(value)
    return AES.encrypt(valueJSON, getSecret()).toString()
}

const decrypt = (value) => {
    //console.log("decrypt :", value)
    try {
        value = value ? AES.decrypt(value, getSecret()) : null
        value = value ? JSON.parse(value.toString(Crypto.enc.Utf8)) : null
        return value
    } catch (e) {
        // console.log("CLEAR LOCAL STORAGE ERROR ", e )
        store.clearAll()
    }
}

const getAccessToken = () => {
    let value = store.get(UFA_ACCESS_TOKEN)
    return decrypt(value)
}

const getAccessRole = () => {
    let value = store.get(UFA_ACCESS_ROLE)
    return decrypt(value)
}

const getAccessName = () => {
    let value = store.get(UFA_ACCESS_NAME)
    return decrypt(value)
}

const getAllAccess = () =>{
    //console.log("get login access all")
    return {
        // name: decrypt(store.get(UFA_ACCESS_NAME)),
        // role: decrypt(store.get(UFA_ACCESS_ROLE)),
        customer_infos: decrypt(store.get(UFA_CUSTOMER_INFOS)),
        token: decrypt(store.get(UFA_ACCESS_TOKEN)),
        expire_time: decrypt(store.get(UFA_CUSTOMER_EXPIRE_TIME)),
        news: decrypt(store.get(WELCOME_NEWS))
    }
}

const getAllAccessFromLocalStorage = () =>{
    // console.log("get login access from localstorage all")
    // console.log(decrypt(store.get(UFA_CUSTOMER_INFOS)))
    // console.log(decrypt(store.get(UFA_ACCESS_TOKEN)))
    return {
        customer_infos: decrypt(store.get(UFA_ACCESS_NAME)),
        //role: decrypt(localStorage.getItem(UFA_ACCESS_ROLE)),
        token: decrypt(store.get(UFA_ACCESS_TOKEN))
    }
}

const getUserId = () => {
    let value = store.get(USER_CUSTOMER)
    return decrypt(value)
}

const setAllAccess = (data) =>{
    store.set(UFA_ACCESS_TOKEN, encrypt(data.token));
    store.set(UFA_CUSTOMER_INFOS, encrypt(data.customer_infos));
    store.set(UFA_CUSTOMER_EXPIRE_TIME, encrypt(data.expire_time));
    store.set(WELCOME_NEWS, encrypt(data.news));
}

const setAccessToken = (value) => {
    store.set(UFA_ACCESS_TOKEN, encrypt(value))
}

const setAccessRole = (value) => {
    store.set(UFA_ACCESS_ROLE, encrypt(value))
}

const setAccessName = (value) => {
    store.set(UFA_ACCESS_NAME, encrypt(value))
}

const setUserId = (value) => {
    store.set(USER_CUSTOMER, encrypt(value))
}

const removeAllAccess = () => {
    store.remove(UFA_ACCESS_TOKEN);
    store.remove(UFA_CUSTOMER_INFOS);
}
const removeAccessToken = () => {
    store.remove(UFA_ACCESS_TOKEN)
}

const removeAll = () => {
    removeAccessToken()
}

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    getAccessToken,
    getAccessRole,
    getAccessName,
    getAllAccess,
    getUserId,
    removeAccessToken,
    removeAllAccess,
    removeAll,
    setAllAccess,
    setAccessToken,
    setAccessRole,
    setAccessName,
    setUserId,
    getAllAccessFromLocalStorage,
}
